<template>
  <div class="supplier">
    <header-search
      ref="headerSearch"
      placeholder="输入供应商名称搜索"
      @search="search"
    />
    <div v-if="list.length > 0" class="list st-wrapper">
      <div
        class="item"
        v-for="(supplier, record_index) in list"
        :key="record_index"
      >
        <div class="st-item" @click="onNextPage(supplier)">
          <div class="top">
            <div class="name">{{ supplier.name }}</div>
            <div class="type">{{ supplier.catalog }}</div>
          </div>
          <div class="bottom">
            <div class="goods">{{ supplier.items || '暂无食材' }}</div>
            <div class="right">
              <span>查看食材列表</span>
              <img src="../../../../assets/images/icon/arrow-r-m.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <empty
      icon="supplier"
      text="未获取到供应商信息"
      height="calc(100vh - 3rem)"
      v-else
    />
    <footer-button text="新增食材" @confirm="confirm" />
  </div>
</template>
<script>
import HeaderSearch from '../../../../components/header/search/Index'
import FooterButton from '../../../../components/footer/button/Index'
import Empty from '../../../../components/empty/Index'
export default {
  components: {
    HeaderSearch,
    FooterButton,
    Empty
  },
  data () {
    return {
      list: [],
      oldList: []
    }
  },
  created () {
    this.getSupplier()
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 获取供应商列表
    getSupplier () {
      this.$api.supplier.list({
        bid: this.$route.query.bid
      }).then(res => {
        // console.log('供应商列表:', res)
        if (res.data.success) {
          this.list = this.oldList = res.data.result.map(item => {
            if (item.items) {
              item.items = item.items.join('、')
            }
            return item
          })
        }
      })
    },
    // 前往 食材列表页
    onNextPage (supplier) {
      this.$router.push({
        name: 'FoodList',
        query: {
          bid: this.$route.query.bid,
          title: supplier.name,
          type: supplier.catalog,
          supplyId: supplier._id
        }
      })
    },
    handleScroll () {
      // 获取滚动时的高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const dom = this.$refs.headerSearch.$el.querySelector('.search-item')
      if (scrollTop >= 20) {
        dom.style.top = '0'
      } else {
        dom.style.top = ''
      }
    },
    search (value) {
      // console.log(value)
      let list = this.oldList
      if (value) {
        list = list.filter(item => {
          return item.name.indexOf(value) !== -1
        })
        // console.log(list)
        this.list = list
      } else {
        this.list = list
      }
    },
    confirm () {
      this.$router.push({
        name: 'AddFood',
        query: {
          supplyId: null,
          bid: this.$route.query.bid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
